import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Klimatyzatory = () => {
  return (
    <div id="klimatyzatory">
      <StaticImage
        src="../../assets/images/klimatyzacja/klimatyzatory.png"
        alt="klimatyzatory"
        placeholder="tracedSVG"
        className="klimatyzatory"
      />
      <StaticImage
        src="../../assets/images/klimatyzacja/klimatyzatory_loga.png"
        alt="klimatyzatory_loga"
        placeholder="tracedSVG"
        className="klimatyzatoryLoga"
      />
      <div className="klimaElement">
        <StaticImage
          src="../../assets/images/klimatyzacja/systemy.jpg"
          alt="square"
          placeholder="tracedSVG"
          className="elementBackground"
        />
        <div className="text-holder" lang="en">
          <h3>SYSTEMY</h3>
          <p>
            Oferujemy klimatyzatory działające w dwóch systemach – split i
            multisplit:
          </p>
          <p>
            <b>SPLIT</b> – system, w którym jednej jednostce zewnętrznej
            odpowiada jedna jednostka wewnętrzna.
          </p>
          <p>
            <b>MULTI SPLIT</b> – system klimatyzowania, który umożliwia
            podłączenie zestawu wielu niezależnych jednostek wewnętrznych do
            jednej jednostki zewnętrznej, co pozwala na równoczesne chłodzenie
            kilku pomieszczeń. Rozwiązanie to daje możliwość niezależnego
            regulowania wydajności każdego urządzenia.{" "}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Klimatyzatory
