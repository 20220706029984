import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Klima = () => {
  return (
    <div id="klima">
      <div className="klimaElement">
        <StaticImage
          src="../../assets/images/klimatyzacja/klimatyzacja.png"
          alt="klimatyzacja"
          placeholder="tracedSVG"
          className="elementBackground"
        />
        <div className="text-holder" lang="en">
          <h3>KLIMATYZACJA</h3>
          <p>
            Ma za zadanie utrzymanie w pomieszczeniu zadanych warunków
            klimatycznych. Przede wszystkim – zapewnienie odpowiedniego zakresu
            temperatury oraz wilgotności powietrza. Pomaga stworzyć dogodne
            warunki do pracy i odpoczynku. Sprawdzi się w biurach, zakładach
            pracy, przestrzeniach publicznych, ale także w domu. Jest to prosty
            i intuicyjny w obsłudze system.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Klima
