import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Link } from "gatsby"

import SideMenu from "../SideMenu"

import "../../assets/css/klimatyzacja.css"

const Menu = () => {
  return (
    <>
      <div className="menu">
        <AnchorLink
          to="/klimatyzacja#klima"
          className="menuElement fourElementMenu"
        >
          <StaticImage
            src="../../assets/images/klimatyzacja/menu/klimatyzacja.png"
            alt="klimatyzacja"
            placeholder="tracedSVG"
          />
          <h3>klimatyzacja</h3>
        </AnchorLink>
        <AnchorLink
          to="/klimatyzacja#montaz_klimy"
          className="menuElement fourElementMenu"
        >
          <StaticImage
            src="../../assets/images/klimatyzacja/menu/montaz.png"
            alt="montaz"
            placeholder="tracedSVG"
          />
          <h3>montaż</h3>
        </AnchorLink>
        <AnchorLink
          to="/klimatyzacja#klimatyzatory"
          className="menuElement fourElementMenu"
        >
          <StaticImage
            src="../../assets/images/klimatyzacja/menu/klimatyzatory.png"
            alt="klimatyzatory"
            placeholder="tracedSVG"
          />
          <h3>klimatyzatory</h3>
        </AnchorLink>
        <AnchorLink
          to="/klimatyzacja#serwis"
          className="menuElement fourElementMenu"
        >
          <StaticImage
            src="../../assets/images/klimatyzacja/menu/serwis.png"
            alt="serwis"
            placeholder="tracedSVG"
          />
          <h3>serwis czyszczenie</h3>
        </AnchorLink>
      </div>
      <SideMenu base="klimatyzacja#klimatyzacja">
        <div className="sideMenuElement">
          <h3>klimatyzacja</h3>
          <AnchorLink to="/klimatyzacja#klima">
            <StaticImage
              src="../../assets/images/klimatyzacja/menu/klimatyzacja.png"
              alt="klimatyzacja"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <div className="sideMenuElement">
          <h3>montaż</h3>
          <AnchorLink to="/klimatyzacja#montaz_klimy">
            <StaticImage
              src="../../assets/images/klimatyzacja/menu/montaz.png"
              alt="montaz"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <div className="sideMenuElement">
          <h3>klimatyzatory</h3>
          <AnchorLink to="/klimatyzacja#klimatyzatory">
            <StaticImage
              src="../../assets/images/klimatyzacja/menu/klimatyzatory.png"
              alt="klimatyzatory"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <div className="sideMenuElement">
          <h3>serwis czyszczenie</h3>
          <AnchorLink to="/klimatyzacja#serwis">
            <StaticImage
              src="../../assets/images/klimatyzacja/menu/serwis.png"
              alt="serwis"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
      </SideMenu>
      <Link className="sideMenuOZEBack" to="/ekologiczna_energia">
        <StaticImage
          src="../../assets/images/oze/oze_back.png"
          alt="sterowanie"
          placeholder="tracedSVG"
        />
      </Link>
    </>
  )
}

export default Menu
