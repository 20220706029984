import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Montaz = () => {
  return (
    <div id="montaz_klimy">
      <div className="klimaElement">
        <StaticImage
          src="../../assets/images/klimatyzacja/montaz.png"
          alt="montaz"
          placeholder="tracedSVG"
          className="elementBackground"
        />
        <div className="text-holder" lang="en">
          <h3>MONTAŻ</h3>
          <p>
            Dobór i montaż klimatyzatorów wymaga wiedzy specjalistycznej,
            odpowiednich umiejętności, narzędzi oraz certyfikatów i zezwoleń.
            Nasza firma oferuje kompleksowe usługi w tym zakresie. Doświadczenie
            i wiedza naszych specjalistów pozwolą na szybki i profesjonalny
            montaż wybranego systemu klimatyzacji. Nasi specjaliści pomogą także
            dobrać odpowiednią moc urządzeń do pomieszczeń, w których mają
            zostać zamontowane.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Montaz
