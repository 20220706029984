import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Serwis = () => {
  return (
    <div id="serwis">
      <div className="klimaElement">
        <StaticImage
          src="../../assets/images/klimatyzacja/serwis.png"
          alt="serwis"
          placeholder="tracedSVG"
          className="elementBackground"
        />
        <div className="text-holder">
          <h3>PRZEGLĄD</h3>
          <p>
            Dla zapewnienia komfortu użytkowania, ale przede wszystkim dla
            bezpieczeństwa, konieczny jest okresowy przegląd oraz serwis
            klimatyzatora. Odpowiednia konserwacja urządzenia zapewnia jego
            poprawne i długoletnie użytkowanie. Oferujemy nie tylko montaż
            urządzenia, ale również jego serwis – także w przypadku usterek.
            Nasze doświadczenie oraz profesjonalizm gwarantują najwyższy poziom
            obsługi.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Serwis
